import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './services/local-storage.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener('contextmenu', ['$event'])
  onRightClick(event: Event) {
    if (environment.production) {
      event.preventDefault();
    }
  }

  constructor(private readonly _localStorageService: LocalStorageService) {
    if (!this._localStorageService.machineId) {
      this._localStorageService.machineId = uuidv4();
    }
  }
}
