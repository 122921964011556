import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {
  token: string | undefined;
  error: string = '';
  isSuccessful: boolean = false;
  verifying: boolean = false;
  secondsCountdown: number = 10;
  countdownSubscription: Subscription;

  constructor(private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _authService: AuthService) {
    this.countdownSubscription = new Subscription();
    const encryptedToken = decodeURIComponent(this._route.snapshot.queryParams['token']);
    if (!encryptedToken) {
      return;
    }
    this.token = crypto.AES.decrypt(encryptedToken, environment.signatureKey).toString(crypto.enc.Utf8);
  }
  ngOnInit(): void {
    this.error = '';
    if (!this.token) {
      this.error = 'invalid token';
      return;
    }
    this.verifying = true;
    this._authService.activateAccount(this.token).subscribe({
      next: (response) => {
        this.isSuccessful = true;
        this.startTimer();
      },
      error: (err) => {
        if (err.error.messages?.length > 0) {
          this.error = err.error.messages.join('\n');
        } else if (err.error.message) {
          this.error = err.error.message;
        }
        this.isSuccessful = false;
      }
    }).add(() => this.verifying = false)
  }

  private startTimer() {
    this.countdownSubscription = timer(0, 1000)
      .subscribe(() => {
        --this.secondsCountdown;
        if (this.secondsCountdown === 0) {
          this.countdownSubscription.unsubscribe();
          this._router.navigate(['login']);
        }
      })
  }

  ngOnDestroy() {
    this.countdownSubscription.unsubscribe();
  }

}
