import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, UserType } from 'src/app/services/auth.service';
import { AvailableApplication } from 'src/app/services/environment.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-companies-list',
  templateUrl: './user-companies-list.component.html',
  styleUrls: ['./user-companies-list.component.scss']
})
export class UserCompaniesListComponent implements OnInit {
  userCompanies: any = [];
  filteredCompanies: any = [];
  isLoading: boolean = false;
  email: string = '';

  constructor(private readonly _route: ActivatedRoute,
    private readonly _authService: AuthService) {
    this.userCompanies = this._authService.userCompanies
      .filter(res => res.isHidden !== true)
      .sort((a, b) => Number(b.isPinned) - Number(a.isPinned));
    this.filteredCompanies = [...this.userCompanies];
  }

  ngOnInit() {
    this.email = this._route.snapshot.queryParams['email'];
  }

  filterCompanyByName(evt: any) {
    const searchString = (evt.target as HTMLInputElement).value;
    this.filteredCompanies = this.userCompanies.filter((uc: any) => !searchString || uc.company.name.toLowerCase().includes(searchString.toLowerCase()))
  }

  companySelected(userId: string, selectedCompany: string) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this._authService.getAuthTokenForCompany(userId, selectedCompany).subscribe({
      next: (token) => {
        this._authService.persistAuthData(this.email, token);
        this._authService.getProductNamesWithLicenses().subscribe({
          next: (availableProducts) => {
            availableProducts = availableProducts.concat([AvailableApplication.Admin, AvailableApplication.Phi])
            const appQueryParam = this._route.snapshot.queryParams['app'];
            if (appQueryParam && availableProducts.includes(appQueryParam.toLowerCase())) {
              this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName((appQueryParam.toLowerCase()) as AvailableApplication, true));
              return;
            }
            if (availableProducts.includes(AvailableApplication.Beta)) {
              this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Beta, true));
              return;
            }
            if (availableProducts.includes(AvailableApplication.Charts)) {
              if (token.userType === UserType.Biller) {
                this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Biller, true));
                return;
              }
              this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Charts, true));
              return;
            }
            if (availableProducts.includes(AvailableApplication.Studies)) {
              this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Studies, true));
              return;
            }
            this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Phi, true));
          },
          error: (err) => {
            console.log(err);
          }
        });
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  pinOrHideCompany(selectedUserCompany: any) {
    const request = this.createRequestPayload(selectedUserCompany);
    this._authService.hideOrPinCompany(request).subscribe({
      next: (res) => {
        console.log(res)
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onPinClick(selectedUserCompany: any) {
    selectedUserCompany.isPinned = !selectedUserCompany.isPinned;
    this.pinOrHideCompany(selectedUserCompany);
  }

  onHideClick(selectedUserCompany: any) {
    selectedUserCompany.isHidden = true;
    this.pinOrHideCompany(selectedUserCompany);
  }

  createRequestPayload(selectedUserCompany: any) {
    return {
      userId: selectedUserCompany.userId,
      companyId: selectedUserCompany.company.id,
      isHidden: selectedUserCompany.isHidden,
      isPinned: selectedUserCompany.isPinned,
    }
  }
}
