import { Injectable } from '@angular/core';
import { AuthData, UserCache } from './auth.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

const authorizationDataKey: string = 'authorizationData';
const userCacheKey: string = 'usersCache';
const machineIdKey: string = 'machineId';
const geoLocationKey: string = 'geoLocation';
const ipAddressKey: string = 'ipAddress';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getItem<T>(key: string): T {
    const savedDataJson = localStorage.getItem(key);
    if (!savedDataJson) {
      return <T>undefined;
    }
    return <T>JSON.parse(savedDataJson);
  }

  hasItem<T>(key: string): boolean {
    const savedDataJson = localStorage.getItem(key);
    return savedDataJson != null;
  }

  removeItem<T>(key: string) {
    localStorage.removeItem(key);
  }

  setItem<T>(key: string, item: T | null | undefined) {
    if (item == null || item == undefined) {
      localStorage.removeItem(key);
      return;
    }
    localStorage.setItem(key, JSON.stringify(item));
  }

  set authorizationData(authData: AuthData | null) {
    if (!authData) {
      this.setItem<AuthData>(authorizationDataKey, null);
      return;
    }
    const encryptedInfo = crypto.AES.encrypt(JSON.stringify(authData), environment.signatureKey).toString();
    this.setItem<string>(authorizationDataKey, encryptedInfo);
  }

  get authorizationData(): AuthData | null {
    const encryptedAuthInfo = this.getItem<string>(authorizationDataKey);
    if (!encryptedAuthInfo) {
      return null;
    }
    //backward compatibility fix: previously we were storing object in local storage now we store encrypted string
    if (typeof encryptedAuthInfo === 'object') {
      this.authorizationData = null;
      return null;
    }
    const decryptedBytes = crypto.AES.decrypt(encryptedAuthInfo, environment.signatureKey);
    return JSON.parse(decryptedBytes.toString(crypto.enc.Utf8));
  }

  set authorizationDataEncrypted(encryptedAuthInfo: string) {
    this.setItem<string>(authorizationDataKey, encryptedAuthInfo);
  }

  get authorizationDataEncrypted(): string {
    return this.getItem<string>(authorizationDataKey);
  }

  get machineId(): string {
    return this.getItem<string>(machineIdKey);
  }

  set machineId(machineId: string | null) {
    this.setItem<string>(machineIdKey, machineId);
  }

  get geoLocation(): string {
    return this.getItem<string>(geoLocationKey);
  }

  set geoLocation(location: string | null) {
    this.setItem<string>(geoLocationKey, location);
  }

  get ipAddress(): string {
    return this.getItem<string>(ipAddressKey);
  }

  set ipAddress(ip: string | null) {
    this.setItem<string>(ipAddressKey, ip);
  }

  set userCache(usersCache: UserCache[] | null) {
    this.setItem<UserCache[]>(userCacheKey, usersCache);
  }

  get userCache(): UserCache[] {
    return this.getItem<UserCache[]>(userCacheKey);
  }

  get refreshToken(): string | undefined {
    const authData = this.authorizationData;
    if (authData && authData.refreshToken) {
      return authData.refreshToken;
    }
    return undefined;
  }
}
