import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {
  secondsCountdown: number = 10;
  countdownSubscription: Subscription;

  constructor(private readonly _route: ActivatedRoute,
    private readonly _router: Router) {
    this.countdownSubscription = new Subscription();
  }
  private startTimer() {
    this.countdownSubscription = timer(0, 1000)
      .subscribe(() => {
        --this.secondsCountdown;
        if (this.secondsCountdown === 0) {
          this.countdownSubscription.unsubscribe();
          this._router.navigate(['login']);
        }
      })
  }

  ngOnInit() {
    this.startTimer();
  }

  ngOnDestroy() {
    this.countdownSubscription.unsubscribe();
  }

}
