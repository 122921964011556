import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoginResponse, AuthToken, ValidOTPResponse, UserCompany, UserCache, UserType } from 'src/app/services/auth.service';
import { NgOtpInputConfig } from 'ng-otp-input';
import { AvailableApplication } from 'src/app/services/environment.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') loginForm: NgForm | undefined = undefined;
  secondsCountdown: number = 0;
  countdownSubscription: Subscription;
  email: string = ''
  otp: string = '';
  inProgress = false;
  error = '';
  returnUrl: string = '';
  showValidationCodeStep = false;
  partialPhone: string = '';
  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6
  };
  // set the currenr year
  year: number = new Date().getFullYear();
  showLoginInput: boolean = false;
  usersCache: UserCache[];
  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _authService: AuthService
  ) {
    this.usersCache = this._authService.cachedUsers || [];
    this.showLoginInput = this.usersCache.length === 0;
    this.countdownSubscription = new Subscription();
  }

  ngOnInit() {
    this._authService.clearAuthFromLocalStorage();
    this._authService.setUserIpAddress();

  }


  onOtpChange(otp: string) {
    this.otp = otp;
  }

  onSubmit() {
    if (!this.showValidationCodeStep) {
      this.validateEmail();
      return;
    }
    this.verifyOTP();
  }

  validateEmail() {
    this.error = '';
    if (!this.loginForm) {
      return;
    }
    this.loginForm.form.markAllAsTouched();
    if (this.loginForm?.invalid) {
      return;
    }
    this.sendCode()
  }

  sendCode() {
    if (this.secondsCountdown > 0) {
      return;
    }

    this.inProgress = true;
    this._authService.login(this.email)
      .subscribe({
        next: (response: LoginResponse) => {
          this.secondsCountdown = 60;
          this.startTimer();
          this.showValidationCodeStep = true;
          this.email = response.email;
          this.partialPhone = response.phone;
          if (!this.showLoginInput) {
            this.showLoginInput = true;
          }
        }, error: (err: any) => {
          this.error = err.error?.message || '';
        }
      }).add(() => {
        this.inProgress = false
      });
  }

  verifyOTP() {
    this.error = '';
    if (!this.otp) {
      this.error = 'Please input verification code.';
      return;
    }
    this.inProgress = true;
    this._authService.validateOTP(this.otp, this.email)
      .subscribe({
        next: (result: ValidOTPResponse) => {
          if (result.status === 'Token') {
            this.saveAuthDataAndRedirect(this.email, <AuthToken>result.response);
          } else {
            this._authService.userCompanies = <UserCompany[]>result.response;
            this._authService.accessToken = result.tempAccessToken || '';
            this._router.navigate(['select-company'], {
              queryParams: {
                app: this._route.snapshot.queryParams['app'],
                email: this.email
              }
            });
          }
        }, error: (err: any) => {
          if (err.error?.messages?.length) {
            this.error = 'Invalid code or code expired.';
          } else {
            this.error = err.error?.message || '';
          }
          this.inProgress = false;
        }
      });
  }

  selectUser(userCache: UserCache) {
    this.email = userCache.userName;
    this.sendCode();
  }

  saveAuthDataAndRedirect(userName: string, token: AuthToken) {
    this._authService.persistAuthData(userName, token);
    this._authService.getProductNamesWithLicenses().subscribe({
      next: (availableProducts) => {
        availableProducts = availableProducts.concat([AvailableApplication.Admin, AvailableApplication.Phi]);
        const appQueryParam = this._route.snapshot.queryParams['app'];
        if (appQueryParam && availableProducts.includes(appQueryParam.toLowerCase())) {
          this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName((appQueryParam.toLowerCase()) as AvailableApplication, true));
          return;
        }
        if (availableProducts.includes(AvailableApplication.Beta)) {
          this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Beta, true));
          return;
        }
        if (availableProducts.includes(AvailableApplication.Charts)) {
          if (token.userType === UserType.Biller) {
            this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Biller, true));
            return;
          }
          this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Charts, true));
          return;
        }
        if (availableProducts.includes(AvailableApplication.Studies)) {
          this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Studies, true));
          return;
        }
        this._authService.redirectToUrlWithToken(this._authService.getUrlByAppName(AvailableApplication.Phi, true));
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  removeAccount(userName: string) {
    this.usersCache = this.usersCache.filter(uc => uc.userName !== userName);
    this.showLoginInput = this.usersCache.length === 0;
    this._authService.removeAccountFromUserCache(userName);
  }

  get isUserAlreadyLoggedIn(): boolean {
    return !this._authService.isTokenExpired;
  }

  private startTimer() {
    this.countdownSubscription = timer(0, 1000)
      .subscribe(() => {
        --this.secondsCountdown;
        if (this.secondsCountdown === 0) {
          this.countdownSubscription.unsubscribe();
        }
      })
  }

  ngOnDestroy() {
    this.countdownSubscription.unsubscribe();
  }
}
