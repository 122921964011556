<div *ngIf="!isLoading" class="container text-center">
    <img id="logo-img" src="assets/images/logo-healthexam.svg" alt="logo health exam" />
    <div class="company-panel">
        <h5 class="mb-2">Choose your organization</h5>
        <div class="d-flex justify-content-center my-4">
            <span class="p-input-icon-left position-relative">
                <i id="list-search-icon" class="fa fa-search"></i>
                <input id="list-search-box" class="form-control" type="text" (input)="filterCompanyByName($event)"
                    placeholder="Search Company" />
            </span>
        </div>
        <div class="d-flex flex-wrap flex-column company-list">
            <div role="button" (click)="companySelected(uc.userId, uc.company.id)"
                class="d-flex justify-content-between align-items-center company-btn"
                *ngFor="let uc of filteredCompanies">
                <div class="me-3">{{uc.company.name}}</div>
                <div (click)="$event.stopPropagation();" class="btn-group" dropdown container="body">
                    <i role="button" class="fa fa-ellipsis-v fs-6" dropdownToggle data-toggle="dropdown"
                        aria-expanded="true"></i>
                    <div class="dropdown-menu dropdown-menu-end company-menu" *dropdownMenu>
                        <span class="dropdown-item fa fa-thumb-tack fs-6" role="button" (click)="onPinClick(uc)">
                            <span class="ms-2">{{uc.isPinned ? 'Unpin from top' : 'Pin to top' }}</span>
                        </span>
                        <span class="dropdown-item" role="button" (click)="onHideClick(uc)">
                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            <span class="ms-2">Hide</span>
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="!filteredCompanies?.length">
                <em>No company available.</em>
            </div>
        </div>
        <div class="mt-5 mb-2">
            <a class="text-center login-link" routerLink="/login">Sign in with different account.</a>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="row d-flex justify-content-center full-height">
    <div class="col-12 align-self-center">
        <div class="row">
            <div class="mx-auto my-auto">
                <div class="text-center">
                    <div class="spinner-border thumb-lg text-primary" role="status"></div>
                    <h5 class="text-muted mt-2">Please wait...</h5>
                </div>
            </div>
        </div>
    </div>
</div>