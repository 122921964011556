import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

let headers: any = {
  'Content-Type': 'application/json',
  'auth-req': 'true',
  'X-Requested-With': 'XMLHttpRequest',
};

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private readonly _http: HttpClient, private readonly _localStorageService: LocalStorageService) {
    headers['user_deviceid'] = this._localStorageService.machineId;
  }

  get = (url: string, appendBaseUrl: boolean = true): Observable<any> => {
    this.appendAdditionalHeaders();
    const urlToUse = appendBaseUrl ? this.apiBaseUrl + url : url;
    return this._http.get(urlToUse, { headers });
  };

  post = (url: string, data: any, authToken: string | undefined = undefined): Observable<any> => {
    this.appendAdditionalHeaders();
    const urlToUse = this.apiBaseUrl + url;
    this.addHmacSignature(data);
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }
    return this._http.post(urlToUse, data, { headers });
  };

  getWithAuthToken = (url: string, token: string): Observable<any> => {
    this.appendAdditionalHeaders();
    const urlToUse = this.apiBaseUrl + url;
    headers['Authorization'] = `Bearer ${token}`;
    return this._http.get(urlToUse, { headers: headers });
  };

  private addHmacSignature(data: any) {
    const payload = JSON.stringify(data);
    const signature = crypto.HmacSHA256(payload, environment.signatureKey).toString(crypto.enc.Hex);
    headers['signature'] = signature;
  }

  private appendAdditionalHeaders() {
    if (this._localStorageService.machineId) {
      headers['user_deviceid'] = this._localStorageService.machineId;
    }
    if (this._localStorageService.geoLocation) {
      headers['user_geolocation'] = this._localStorageService.geoLocation;
    }
    if (this._localStorageService.ipAddress) {
      headers['user_ipaddress'] = this._localStorageService.ipAddress;
    }
    if (navigator?.userAgent) {
      headers['user_agent'] = navigator.userAgent;
    }
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}
