export class Utils {
  static parseJwt(token: string): any {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static getTokenExpireDate(exp: number) {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  }
}
