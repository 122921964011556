<div class="container text-center">
  <h2 *ngIf="verifying">Please wait while we verify your account...</h2>
  <div *ngIf="!isSuccessful && error">
    <img src="assets/images/error.png" alt="error image" class="mt-5" />
    <span class="d-block text-danger fs-6 mt-3">{{error}}</span>
    <a routerLink="/login">back to Login.</a>
  </div>

  <div *ngIf="isSuccessful">
    <img src="assets/images/success.png" alt="success image" class="mt-5" />
    <span class="d-block text-success fs-6 mt-3">
      You account has been activated.
    </span>
    <p class="text-success mt-2 mb-0">
      You are being redirected to login screen in <strong>{{secondsCountdown}}</strong> seconds
    </p>
    <span class="d-block text-success">please login <a routerLink="/login">here</a></span>
  </div>
</div>