<div class="logout-container">
    <div class="logout-overlay">
        <div class="logout-modal">
            <img class="logo-img" src="assets/images/logo-healthexam.svg" alt="" height="30" />
            <h4 class="mt-5 fw-bold">You Signed out of your account</h4>
            <p class="mt-2 mb-4 ">It's a good idea to close all your browser windows.</p>
            <p class="text-success mt-2 mb-0">
                You are being redirected to login screen in <strong>{{secondsCountdown}}</strong> seconds
              </p>
            <a class="text-center login-link mt-4 d-block" routerLink="/login">Go to login page.</a>
        </div>
    </div>
</div>